<template>
  <div class="container-fluid us-layout">
    <div class="container-wrapper">
      <div class="us-layout__row">
        <div class="us-layout__left">
          <a class="cw__logo" :href="allConfig.studioBaseURL">
            <img
              src="@/assets/images/layout/header/logo.svg"
              width="auto"
              height="auto"
              alt="logo"
            />
          </a>
          <div class="us-layout__section">
            <div class="float__content">
              <h3>{{ getCourseDetail.org }}</h3>
              <h6>{{ courseTitle }}</h6>
            </div>
            <b-navbar>
              <b-navbar-nav>
                <b-nav-item-dropdown
                  text="Content"
                  right
                  :class="{ 'us-header__active': currentTab === 'course' }"
                >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/course/${getCourseDetail.course_key_string}`
                    "
                    >Outline</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/course_info/${getCourseDetail.course_key_string}`
                    "
                    >Updates</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/tabs/${getCourseDetail.course_key_string}`
                    "
                    >Pages</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/assets/${getCourseDetail.course_key_string}`
                    "
                    >File & Uploads</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/textbooks/${getCourseDetail.course_key_string}`
                    "
                    >Textbooks</b-dropdown-item
                  >
                </b-nav-item-dropdown>

                <b-nav-item-dropdown
                  text="Settings"
                  right
                  :class="{ 'us-header__active': currentTab === 'settings' }"
                >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/settings/details/${getCourseDetail.course_key_string}`
                    "
                    >Schedule & Details</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/settings/grading/${getCourseDetail.course_key_string}`
                    "
                    >Grading</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/course_team/${getCourseDetail.course_key_string}`
                    "
                    >Course Team</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/group_configurations/${getCourseDetail.course_key_string}`
                    "
                    >Group Configurations</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/settings/advanced/${getCourseDetail.course_key_string}`
                    "
                    >Advanced Settings</b-dropdown-item
                  >
                  <b-dropdown-item href="#" active>Awards</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown text="Tools" right>
                  <b-dropdown-item
                    :href="
                      `${allConfig.faceToFaceURL}/course/${getCourseDetail.course_key_string}`
                    "
                    >Mark Attendance</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/import/${getCourseDetail.course_key_string}`
                    "
                    >Import</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/export/${getCourseDetail.course_key_string}`
                    "
                    >Export</b-dropdown-item
                  >
                  <b-dropdown-item
                    :href="
                      `${allConfig.studioBaseURL}/checklists/${getCourseDetail.course_key_string}`
                    "
                    >Checklists</b-dropdown-item
                  >
                  <b-dropdown-item
                    target="_blank"
                    :href="
                      `${allConfig.studioInsightsURL}superset/dashboard/10/?preselect_filters={'33':{'display_name':${getCourseDetail.display_name}}}&show_filters=0`
                    "
                    >Analytics</b-dropdown-item
                  >
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-navbar>
          </div>
        </div>
        <div class="us-layout__right">
          <b-navbar class="float-right">
            <b-navbar-nav>
              <b-nav-item
                href="https://edx.readthedocs.io/projects/open-edx-building-and-running-a-course/en/open-release-quince.master/course_assets/course_files.html"
                title="Contextual Online Help"
                target="_blank"
              >
                <img
                  src="@/assets/images/layout/header/help.svg"
                  width="auto"
                  height="auto"
                  alt="add"
                />
              </b-nav-item>

              <b-nav-item-dropdown>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <img
                    src="@/assets/images/layout/header/user-profile.svg"
                    width="auto"
                    height="auto"
                    alt="add"
                  />
                </template>
                <b-dropdown-item :href="allConfig.studioBaseURL"
                  >Studio Home</b-dropdown-item
                >
                <b-dropdown-item :href="getFeedbackUrl"
                  >Feedback</b-dropdown-item
                >
                <b-dropdown-item @click="logOut()">Sign Out</b-dropdown-item>
              </b-nav-item-dropdown>
              <!-- Navbar dropdowns -->
            </b-navbar-nav>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["allConfig", "getUserProfile", "getCourseDetail"]),
    courseTitle() {
      return this.getCourseDetail.display_name || this.getCourseDetail.title;
    },
    currentTab() {
      let tab = "course";
      if (this.$route.path.includes("awards")) {
        tab = "settings";
      }
      return tab;
    },
    getFeedbackUrl() {
      return `https://files.lxp.academy.who.int/user-feedback/feedback.html?app=studio&page_url=${location?.href}&email=${this.getUserProfile.email}&subject=overall_platform`;
    }
  },
  methods: {
    logOut() {
      this.$keycloak.logout({
        redirectUri: this.allConfig.studioLogoutURL // to logout studio
      });
    },
    redirect(name) {
      this.$router.push({ name });
    },
    navigateLearningSpace() {
      window.location.href = `${this.allConfig.learningSpaceURL}/learning-space`;
    }
  }
};
</script>
<style lang="scss">
.us-layout {
  background: #ffffff;
  box-shadow: 0px 4px 8px 4px rgb(33 33 33 / 10%);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 64px !important;
  position: relative;
  top: 0;
  left: 0;
  .container-wrapper {
    width: 100%;
    .us-layout__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .us-layout__left {
        display: flex;
        flex-grow: 1;
        .us-layout__section {
          padding-left: 5%;
          display: flex;
          justify-content: center;
          align-items: center;
          .float__content {
            padding: 0px 14px;
            border-right: 2px solid #ccc;
            & h3 {
              margin: 0;
              font-size: 14px;
              color: var(
                --SEMANTIC-On-surface-3,
                var(--Semantic-SURFACE-Onsurface3, #a0a0a0)
              );
            }
            & h6 {
              margin: 0;
              font-size: 14px;
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .navbar {
        padding: 0;
        .nav-item {
          margin-right: 16px;
          &.us-header__active {
            color: #0075b4;
          }
          .dropdown-menu {
            padding: 10px 15px;
            right: 0;
            li {
              margin: 0 0 5px 0;
              border-bottom: 1px solid #f2f2f2;
              padding: 0 0 5px 0;
              &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
              }
              .dropdown-item {
                padding: 0;
                @include label-large();
                &.active {
                  background: transparent;
                  color: #1176b2;
                }
              }
            }
          }
          .nav-link {
            padding: 8px 16px;
            @include body-regular(500);
            letter-spacing: 1.25px;
            text-transform: capitalize;
            &.active {
              background: $brand-primary-100;
              mix-blend-mode: normal;
              border-radius: 8px;
              color: $brand-primary-400;
            }
          }
        }
      }
    }
  }
}
</style>
